import React from 'react';
import SEO from '../../components/seo';
import SimpleHero from '../../components/simple-hero';
import Navigation from '../../components/navigation';

import { Languages } from '../../tools/languages';
import { PrimaryButton } from '../../components/buttons';
import ContactCTA from '../../components/contact-cta';

import PlaceholderImage from '../../../static/images/placeholder.jpg';

const DutchNotFoundPage = ({ location, language = Languages.NL }) => {
  const pathName = location.pathname;

  return (
    <>
      <SEO pathName={pathName} title="404" description="Niet gevonden" lang={language} />
      <Navigation transparent />
      <main>
        <SimpleHero image={PlaceholderImage} alt="404">
          <h1>Oeps, je bent verdwaald</h1>
          <p>
            Klik gerust op de knop hieronder en je bent binnen no-time weer op de juiste plek.
            Succes!
          </p>
          <div>
            <PrimaryButton to="/">Breng me terug</PrimaryButton>
          </div>
        </SimpleHero>
        <ContactCTA language={language} />
      </main>
    </>
  );
};

export default DutchNotFoundPage;
